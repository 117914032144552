import React, { useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import './Signup.css'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Otpmodal from "./Otpmodal";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSignUp } from "../components/Redux/Action";
import { FaFontAwesome } from "react-icons/fa";
 


const Signup = () => {


    const [name, setName] = useState("")
    const [number, setNumber] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [otpmodalopen, setOtpmodalopen] = useState(false)
    const [loginname, setLoginame] = useState("")
    const [loginpassword, setLoginpassword] = useState("")
    const dispatch=useDispatch()
    const [newname, setNewname] = useState("")

    const [postalCode,setPostalCode]=useState("");
    const [Cname,setCName]=useState("");
    const [address,setAddress]=useState("");
    const [fullname,setFullName]=useState("");
const [singINF,setSingInF]=useState(false)

let dropdownData=[{id:1,name:"General Customar",value:"GeneralCustomer"},{id:2,name:"Shop Keeper",value:"ShopKeeper"}]
    const [dropdownV,setDropDownV]=useState(false)
    const [country,setCountry]=useState('');
    const [customerType,setCustomerType]=useState("")
    const [city,setCity] = useState("");
    const [CIF,setCIF]=useState("");
    const [fileD,setFileD]=useState("")
const [phone,setPhone]=useState("")

    const setCooki = (cname, cvalue, cexp) => {
        const hour = new Date();
        hour.setTime(hour.getTime() + (cexp * 60 * 60 * 1000));
        let expire = "expires =" + hour.toUTCString();
        document.cookie = cname + "=" + cvalue + "ue;" + expire + ";path=/";
    }
    const  getCooki = (cookiname) => {
        let cname = cookiname + "=";
        let csplit = document.cookie.split(';');
        for (let i = 0; i < csplit.length; i++) {
            let c = csplit[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cname) == 0) {
                return c.substring(cname.length, c.length);
            }
        }
        return "";
    }

    const loginApi = () => {
console.log("data in signin ===>",loginpassword,loginname)
        axios.get(`${window.sbapi}/api/partyregister/PartyLogin?mobileoremail=${loginname}&password=${loginpassword}&partyType=c`).then((rep) => {
            if (rep.data.Message === 'Success') {
dispatch(setSignUp(rep.data))
                window.orderhistery = rep.data.Data.PartyCode
                console.log("data of signIN api",JSON.stringify(rep.data) )
                toast.success(' You are Successfully Login', {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoginame("")
                setLoginpassword("")
                setCooki(loginname, rep.data.Data.PartyName, 1)
                setNewname(getCooki(loginname))
                window.accountname = loginname
                if (getCooki(loginname)!='') {
                    document.getElementById('accountname').style.display = "none"
                    document.getElementById('accountnamedisplay').style.display = "flex"
                    document.getElementById('accountnamedisplay').style.onChange ="false"
                }
                else {
                    document.getElementById('accountname').style.display = "flex"
                    document.getElementById('accountnamedisplay').style.display = "none"
                }
            }
            else {
                console.log("what error =====> : ",rep.data)
                alert("Pleas Check Your Username & Password ")
            }
        })
    }

    const getotp = () => {
        axios.post(`${window.sbapi}/api/partyregister/OTPForRegister?mobile=${number}&partyType=c`)
            .then((res) => {
                if (res.data.Message === "Success") {
                    setOtpmodalopen(true)
                }
                window.otpphone = res.data.Data.OTP
            })
    }

    const signupApi = () => {

        let flag = true;
        let toastMessage = null;

        if (name.length < 3) {
            toastMessage = "Name Is Required"
        }
        else if (number.length < 11) {
            toastMessage = "Number Is Required"
        }
        else if (email.length < 0) {
            toastMessage = "Email Is Required"
        }
        else if (password.length < 5) {
            toastMessage = "Password Is Required"
        }
        else {
            console.log("password",password)
            axios.post(`${window.sbapi}/api/partyregister/SavePartySignUp`,
                {
                    "PartyName": name,
                    "Mobile": number,
                    "Email": email,
                    "PartyAddress": address,
                    "cust-type":customerType? customerType?.value:"genralcustomer",
                    "Password": password,
                    "PartyType": "c",
                    "Phone":phone,
                    "Country":country,
                    "City":city,
                    "BusinessName":Cname,
                    "PostalCode":postalCode,
                    " CIF_NIF":CIF,
                    "UserId": "0000000001",
                    "BusinessId": "0000000001"
                }).then((response) => {
                    
                    if (response.data.Message === 'Success') {
                        setSingInF(true)
                        console.log("data of signup api",response.data)
                        console.log("data of signup api",JSON.stringify(response.data) )
                        toast.success('You Are Successfully Register', {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setName("")
                        setEmail("")
                        setNumber("")
                        setPassword("")
                    } else {
                        // console.log('Fail')
                    }
                }).catch((er) => {
                    // console.log('er', er)
                })
        }
        if (flag) {
            toast.warn(toastMessage, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (

        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Header />
            {/* <div style={{margin:"5px",display:"flex",height:"40px",    backgroundColor:"#48afff",justifyContent:"center", alignItems:"center" }}>
                        <h2 style={{color:"white",fontSize:"28px" , fontWeight:"bold"}}>Create An Account</h2>
                    </div> */}
            <div className="login-signup">
 
     <div className="login-ac">
    <div className="login-a">
        <h1 style={{color:"white"}}>Login Your Account</h1>
    </div>
    <div className="login-b">

        <h3>Login Information</h3>
        <input type='text' value={loginname} onChange={e => setLoginame(e.target.value)} placeholder="E-mail / Mobile Number" />
        <input type='password' value={loginpassword} onChange={e => setLoginpassword(e.target.value)} placeholder="Password" />
        <Link to="/recovery"><p className="forget1">Forget Password?</p></Link>
    </div>

    <div className="login-btn" style={{}}>
        <button onClick={loginApi}> Login </button>
    </div>
</div>
<div className="signup-ac">
                    <div className="forms-a">
                        <h1 style={{color:"white"}}>Create An Account</h1>
                    </div>
                    <div className="forms-b">
                        <h3>Personal Information</h3>

                        <div className="form-row" >
                            <div className="inputV">
                                <div className="View-Lable">
                                    User Name:
                                </div>
                                <input type='text' value={name} onChange={e => setName(e.target.value)} placeholder="User Name" />

                            </div>
                            <div className="inputV">
                            <div className="View-Lable">
                                    Full Name:
                                </div>
                                <input type='text' value={fullname} onChange={e => setFullName(e.target.value)} placeholder="Full Name" />
                            </div>


                        </div>

                        {/* <div className="getuserdata" style={{display:"flex"}} id="geuserdata"> */}
<div className="form-row">
    <div className="inputV">
    <div className="View-Lable">Address: </div>
    <input type='text' value={address} onChange={e => setAddress(e.target.value)} placeholder="Address" />
    </div>

    <div className="inputV">
    <div className="View-Lable">Email: </div>
    <input type='email' value={email} onChange={e => setEmail(e.target.value)} placeholder="E-mail" />
    </div>
 
                     

</div>
                

<div className="form-row">

<div className="inputV">
    <div className="View-Lable">Company Name: </div>
    <input type='text' value={Cname} onChange={e => setCName(e.target.value)} placeholder="Company Name" />
    </div>

    <div className="inputV">
    <div className="View-Lable">Country: </div>
    <input type='text' value={country} onChange={e => setCountry(e.target.value)} placeholder="Country" />
    </div>


         

</div>

<div className="form-row">

<div className="inputV">
    <div className="View-Lable">City: </div>
    <input type='text' value={city} onChange={e => setCity(e.target.value)} placeholder="City" />    </div>

    <div className="inputV">
    <div className="View-Lable">Postal Code: </div>
    <input type='text' value={postalCode} onChange={e => setPostalCode(e.target.value)} placeholder="Postal Code " />

    </div>




</div>
              
<div className="form-row">

<div className="inputV">
    <div className="View-Lable">Mobile No: </div>
    <input maxLength={11} type='tel' value={number} onChange={e => setNumber(e.target.value)} placeholder="Mobile Number" />

    </div>




    <div className="inputV">
    <div className="View-Lable">Phone: </div>

    <input maxLength={11} type='tel' value={phone} onChange={e => setPhone(e.target.value)} placeholder="Phone Number" />

    </div>


    </div>       




    <div className="form-row">

    <div className="inputV">
    <div className="View-Lable">CIF/NIF: </div>

    <input type='text' value={CIF} onChange={e => setCIF(e.target.value)} placeholder="CIF/NIF" />

    </div>



    <div className="inputV">
    <div className="View-Lable"> Password: </div>
    <input type='password' value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />

    </div>



        </div>        

      
 

                            <div onClick={()=>{ setDropDownV(!dropdownV);}} style={{border:"1px solid",display:"flex",margin:"5px", alignItems:"center", justifyContent:"space-between",padding:"5px",backgroundColor:"white",borderRadius:"5px", border:"1px solid #D3D3D3"}}>
                                <div >{customerType?customerType.name:"Select Customer Type "}  </div>
<div  >{
    dropdownV?<i  class="fa fa-angle-up" />:<i  class="fa fa-angle-down" />
    }
 
</div>

 
                            </div>
                            {/* <div style={{display:dropdownV?"flex":"none", }} > */}

                                <div style={{ display:dropdownV?"flex":"none",flexDirection:"column", border:"0.4px solid #D3D3D3",backgroundColor:"white", borderRadius:"5px", height:"60px"}}>
                            {
                                
                                dropdownV? dropdownData.map((item,k)=>{
                                    return(
                                        <>
                                    <div className="SDItem" onClick={()=>{setCustomerType(item);setDropDownV(false)}}  style={{borderBottom:k==1?"0px solid": "1px solid #D3D3D3"}} >
                                        <div>{item.name}</div>
                                    </div>
                                    </> 
                                    )
                                   
                                }):null
                            }
                            </div>
                            {/* </div> */}




                            <input type="file" value={fileD} onChange={e => setFileD(e.target.value)}    />
                            <p className=" news-letters"> Sign up for news letters? </p>
                            <div>
                                <div className="btn" >
                                    <button onClick={ signupApi} > Register </button>
                                   
                                </div>
                            </div>
                        {/* </div> */}

                        {/* <div className="get-otp" id="getotp" style={{display:"none"}} >
                            <input maxLength={11} type='tel' value={number} onChange={e => setNumber(e.target.value)} placeholder="Mobile Number" />
                            <div>
                                <div className="btn">
                                    <button onClick={getotp}>Get Otp</button>
                                </div>
                                <span style={{ position: 'relative' }}>
                                    <span className='open-otpmodal' style={{ display: otpmodalopen ? 'flex' : 'none', }}>
                                        <Otpmodal closeOtpmodal={setOtpmodalopen} />
                                    </span>
                                </span>
                            </div>
                        </div> */}
                        
                    </div>
                </div>
 
               
  
                

            </div>

            <Footer />
        </>
    )
}

export default Signup;
import React from 'react'
import apples from "./../assets/brands/apple2.png"
import sumsungs from "./../assets/brands/sumsang.png"
import dell from "./../assets/brands/dell.jpg"
import Slider from 'react-slick'
import hp from "./../assets/brands/hp2.jpg"
import ibm from "./../assets/brands/ibm.jpg"
import nokia from "./../assets/brands/nokia.png"
const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 1536,
            settings: {
                slidesToShow: 6,
            },
        },
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 6,
            },
        },
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 6,
            },
        },
        {
            breakpoint: 1024,
            settings: {

                slidesToShow: 6,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 500,
            settings: {

                slidesToShow: 3,
            },
        },
        {
            breakpoint: 400,
            settings: {

                slidesToShow: 3,
            },
        },
    ],
}



const Brands = () => {
    return (
        <>
            <div className="container-fluid brands" >
                <div className='container mt-5'>
                    <div data-aos="zoom-in" data-aos-duration="500" className="row  ">
                        {/* <div className="col-md-2 brands ">
                            <div className="Line "></div>
                        </div> */}
                        <div className="col-md-12">
                            <h1 className="text-center arrival" style={{color:"#48afff"}}>Top Brands Asseccories In The World</h1>
                            <p style={{fontSize:16}}>
                            We offer freebase and nicotine salt e-liquids from all major brands, including VGOD, 7 Daze, Pod Salt, Naked100, Dinner Lady, IVG, VEIIK, and many more, to cater to a broader vaping audience. Additionally, we bring some of the best vape device brands in Pakistan, such as Smok, Uwell, GeekVape, FreeMax, Innokin, JustFog, Suorin, Vaporesso, VooPoo, Aspire, Relx, Myle, Juul, and many others.
   
                            </p>
                        </div>
                        {/* <div className="col-md-2 brands">
                            <div className="Line "></div>

                        </div> */}
                    </div>
                </div>


                <div className="col-md-12 col-sm-12 col-xs-12 feature-seating">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 text-center  ">
                            <Slider {...settings}>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="BLVK" src={apples} />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="VGOD" src={hp} />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="POP" src={dell} />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="ZENITH" src={ibm} />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="NASTY" src={sumsungs} />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="SAD BOY" src={nokia} />
                                </a>

                
                            </Slider>
                        </div>



                    </div>

                </div>




            </div>
        </>
    )
}

export default Brands

import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import aboutUs from "../../src/assets/banner/about-us-banner.jpg"

import './Common.css';

import Maps from "./Maps";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>aboutus</title>
        <meta name="description" content="About Us VapeHouse Pakistan – Premium E-Juices, Electronic Cigarettes and Supplies Vape House.pk is Pakistan's #1 source for premium vape related products that you can buy online. We know that quality is the most important factor when it comes to vaping. We only carry the best brands in the world that are known" />
      </Helmet>
      <Header />
      <div className="aboutus">
        <img src={aboutUs} alt='About Us' className="headingc" />
        <div id="welcom" style={{ color: "black", alignItems:"center" }}>Welcome to The IPRo Movils!</div>
        <div className="card mb-3 mx-5 about" >
          <div className="row g-0  " >
            <div className="col-lg-4">

            </div>
            <div className="col-lg-8 text-light">
              <div className="card-body" id="SecRow" >

                <p className="card-text about-text" >As one of the leading online retailers since 2020,
                  This is proudly purveys the best in third-party,leptop, mobile, protector, asseccories etc. Our specialist knowledge
                  and dedication to excellence will help to enhance every aspect of your Devices
                  experience, and each product has been carefully chosen by mobile, for users.
                  We source directly from the manufacturer and offer unrivalled customer
                  service, allowing you to shop with complete confidence every step of the way.</p>
                <p className="card-text"><small className="text-muted ms-5">Last updated 3 mins ago</small></p>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* <div style={{ marginBottom: "30px" }}><Maps /></div> */}
      <Footer />
    </>
  )
}

export default AboutUs;
import React from 'react'
import "./ElementVape.css";
import { useSelector } from 'react-redux';

const Banner = () => {

  const signupInfo = useSelector(state => state.ReducerSignUpInfo)
  
  return (
    <>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark  headingtopbar">
        <div className="container-fluid">

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <marquee scrolldelay="200" className="ms-5" style={{ color: "white", fontSize: "16px" }}><b>Free Shipping</b> on all orders above $3,000 &nbsp; &nbsp;&nbsp;</marquee>
            </ul>
            {
              signupInfo?.Data?.Approved=="I"?
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <h3 scrolldelay="200" className="ms-5" style={{ color: "yellow", fontSize: "16px" }}><b>Waiting for Approved...</b> &nbsp; &nbsp;&nbsp;</h3>
            </ul>:null
            }
           
            <ul className="tophedersocials mt-2">

              {/* <li className="socialicon"><a target="_blank" href="#"><i class="fa fa-map-marker">&nbsp;</i>Store Near Me</a></li>&nbsp;&nbsp; */}
              <li className="socialicon"><a target="_blank" href="tel:+34600000703"><img src="https://res.cloudinary.com/vape-house/image/upload/v1702742335/telephone_ujuaeu.png" className='icons' />&nbsp;(600 000 703)</a></li>&nbsp;&nbsp;
              <li className="socialicon"><a target="_blank" href="https://www.facebook.com"><img src="https://res.cloudinary.com/vape-house/image/upload/v1702742303/facebook_y5ej5t.png" className='icons' /></a></li>&nbsp;&nbsp;
              <li className="socialicon"><a target="_blank" href="https://www.instagram.com"><img src="https://res.cloudinary.com/vape-house/image/upload/v1702742475/instagram_krtghl.png" className='icons' /></a></li>&nbsp;&nbsp;
              {/* <li class="socialicon"><a target="_blank" href="#"><img src="youtube.png" className='icons' /></a></li>&nbsp;&nbsp; */}
              <li className="socialicon"><a target="_blank" href="https://api.whatsapp.com/send/?phone=34600000703&text=Welcome&app_absent=0"><img src="https://res.cloudinary.com/vape-house/image/upload/v1702742275/whatsapp_rx6jvs.png" className='icons' /></a></li>&nbsp;&nbsp;

            </ul>
          </div>
        </div>
      </nav>

    </>
  )
}

export default Banner
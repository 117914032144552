import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleImageSlider from "react-simple-image-slider";
import { useSelector, useDispatch } from 'react-redux';
import { ChangeName, PoductsApi } from '../Redux/Action';
import { FaChevronRight } from 'react-icons/fa';
import { BsFillCalendarCheckFill } from 'react-icons/bs';
import { VscAccount } from 'react-icons/vsc';
import { GiBallHeart, GiWheat } from 'react-icons/gi';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Allapiproduct from './Allapiproduct'
import { IoIosCall } from 'react-icons/io';
import '../Header/Header.css';
import CarouselSlider from '../../Screens/CarouselSlider';
import Reviews from '../../Screens/Reviews';
import Pakistan from '../../Screens/Pakistan';
import Brands from '../../Screens/Brands';
import Promotion from '../../Screens/Promotion';
import Eliquids from '../Body/assets/12.jpeg'
import Accessories from '../Body/assets/11.jpeg'
import pic1 from "./../../assets/banner/banner-20230605145334-scaled.jpg"
import pic2 from "./../../assets/banner/cbb-banner-5.jpg"
import pic3 from "./../../assets/banner/cbb-banner-6.jpg"
import pic4 from "./../../assets/banner/cbb-banner-7.jpg"
import picb21 from "./../../assets/banner2/cbb-banner1.jpg"
import picb22 from "./../../assets/banner2/cbb-banner2.jpg"
import picb23 from "./../../assets/banner2/cbb-banner4-1.jpg"
import picb24 from "./../../assets/banner2/1.png"
import picb25 from "./../../assets/banner2/banner333.png"
import mobiles from "./../../assets/banner2/mobile.jpg"
import leptops from "./../../assets/banner2/laptop.jpg"
import asseccories from "./../../assets/banner2/assecc.jpg"
import protector from "./../../assets/banner2/protector.jpg"




const Body = () => {
    const fetchAp = useSelector(state => state.Reducerprodata)
    const DepartmentData = useSelector(state => state.ReducerDepData)
    const [depData, setDepData] = useState(null);
    const [proData, setProData] = useState(null);
    const [proData1, setProData1] = useState(null);
    const [scroll, setScroll] = useState()
    const hddispatch = useDispatch()
    const [openmodal, SetmodalOpen] = useState(false);


    useEffect(() => {
        if (DepartmentData !== 'abc') {
            const updatedData = DepartmentData.map((item,key) => {
                item.isShown = false;
                return { ...item }
            })
            setDepData(updatedData)
        }
    }, [DepartmentData])


    const sliderImages = [
        // URL = 'S1.WEBP',
        // URL = pic1,
        URL = pic2,
        // URL = pic3,
        // URL = pic4,
        // URL = 'https://res.cloudinary.com/vape-house/image/upload/v1702739431/S2_fhyvki.webp',

    ]

    useEffect(() => {
        if (fetchAp !== 'abc') {
            setProData(fetchAp.newArrival)
            setProData1(fetchAp.promotional)
        }
    }, [fetchAp])

    const counter = (val) => {
        let client = 0;
        let years = 0;
        let fresh = 0;
        let ingree = 0;
        if (val) {

            const timer = setInterval(() => {
                document.getElementById('ingreedent').innerHTML = ++ingree;
                if (ingree === 95) clearInterval(timer);
            }, 10 / 100);


            const timer1 = setInterval(() => {
                document.getElementById('client').innerHTML = ++client;
                if (client === 99) clearInterval(timer1);
            }, 10 / 100);

            const timer2 = setInterval(() => {
                document.getElementById('years').innerHTML = ++years;
                if (years === 90) clearInterval(timer2);
            }, 10 / 100);

            const timer3 = setInterval(() => {
                document.getElementById('halal').innerHTML = ++fresh;
                if (fresh === 98) clearInterval(timer3);
            }, 10 / 100);
        }
    }

    return (
        <>
            

            <div className='all-body-items' >
                <div className='display-image'>
                    <div className='slider-image'>

                        <div className='banner-image'>
                            <SimpleImageSlider
                                width={'100%'}
                                height={'30vw'}
                                images={sliderImages}
                                showBullets={false}
                                showNavs={false}
                                autoPlay={true}
                                autoPlayDelay={2}
                                style={{ position: 'relative' }}
                            />
                        </div>

                    </div>

                    <div className='mobile-image-slider'>
                        <SimpleImageSlider
                            width={'100%'}
                            height={'40vw'}
                            images={sliderImages}
                            showBullets={false}
                            showNavs={false}
                            autoPlay={true}
                            autoPlayDelay={2}
                            style={{ position: 'relative' }}
                        />
                    </div>

                </div>
                <Pakistan  indi={"2"}/>
                <div className='main-deiv3'>

                    <div className='firstt-div'>

                        <Link to={'/products'} onClick={(e) => {
                            var dep = "0001";

                            var dpgr = {
                                department: "Mobile",
                                Dcode: dep,
                              }
                              hddispatch(ChangeName(dpgr))

                            hddispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}>
                            <div className='firstt-inner'  style={{backgroundColor:"#033a7b"}}>
                                <h5 className='main-hadd'> Mobile</h5>
                                <div>
                                    <button className='rtin-buttton'> <FaChevronRight /> see more</button>

                                </div>
                            </div>

                            <div className='second-inner' >
                                <img alt='cardspic/Eliquids' src={mobiles} />
                            </div>
                        </Link>

                    </div>



                 


                    <div className='centerr-div'>
                        <Link to={'/products'} onClick={(e) => {
                            var dep = "0002";
                            var dpgr = {
                                department: " Leptop",
                                Dcode: dep,
                              }
                              hddispatch(ChangeName(dpgr))
                            hddispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}>
                            <div className='centerr-inner'  style={{backgroundColor:"#033a7b"}}>
                                <h5 className='main-hadd'>Leptop</h5>
                                <div>

                                    <button className='rtin-buttton'> <FaChevronRight /> see more</button>

                                </div>
                            </div>
                            <div className='second-inner' >
                                <img alt='cardspic/Device' src={leptops} />
                            </div> </Link>

                    </div>
                    <div className='lastt-div'>
                        <Link to={'/products'} onClick={(e) => {
                            var dep = "0003";
                            var dpgr = {
                                department: "Accessories",
                                Dcode: dep,
                              }
                              hddispatch(ChangeName(dpgr))
                            hddispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}>
                            <div className='lastt-inner'  style={{backgroundColor:"#033a7b"}}>
                                <h5 className='main-hadd'> Accessories</h5>
                                <div>

                                    <button className='rtin-buttton'> <FaChevronRight /> see more</button>

                                </div>
                            </div>
                            <div className='second-inner' >
                                <img alt='cardspic/Tanks' src={asseccories} />
                            </div>    </Link>
                    </div>
                    <div className='lastt-div'>
                        <Link to={'/products'} onClick={(e) => {
                            var dep = "0004";
                            var dpgr = {
                                department: "Protector",
                                Dcode: dep,
                              }
                              hddispatch(ChangeName(dpgr))
                            hddispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}>
                            <div className='lastt-inner' style={{backgroundColor:"#033a7b"}}>
                                <h5 className='main-hadd'>Protector</h5>
                                <div>

                                    <button className='rtin-buttton'> <FaChevronRight /> see more</button>

                                </div>
                            </div>
                            <div className='second-inner' >
                                <img alt='cardspic/Accessories' src={protector} />
                            </div>      </Link>
                    </div>


                </div>

                <div className='sec-section'>
                    <div className='eliment-container'>
                        <div className='eliment-row'>
                            <div className='eliment-wrap'>
                                <div className='eliment-sec1'>

                                    <h2 data-aos="fade-left" data-aos-duration="1000" className="rtin-title" style={{ color:"#48afff" }} >We Offer People Best Way </h2>
                                    <h2 data-aos="fade-right" data-aos-duration="1000" className="rtin-title" style={{color:"#48afff"}}>  To Use Best Quality </h2>
                                </div>

                            </div>
                        </div>
                    </div>





                    <div className='menu-top-pro'>
                        <ul className='menu-top-con'>
                          
                            {
                                depData?.map((item, index) => {

                                    return (

                                        <li className=' nav-item' onClick={(e) => {
                                            var dep = depData[index].value = item.DepartmentCode;
                                            hddispatch(PoductsApi(dep, ''))
                                            e.stopPropagation();

                                            document.getElementById('all-procont').style.display = "none"

                                            document.getElementById('pro-cont').style.display = "flex"
                                        }}>{item.DepartmentName}</li>

                                    )
                                })
                            }
                        </ul>




                        

                        <div id='all-procont' style={{ maxWidth: "1200px", marginLeft: "auto", marginRight: "auto" }}>
                            <Allapiproduct />
                        </div>
                        <div className='proo-container' id='pro-cont'>
                            <Allapiproduct />

                        </div>

                    </div>
                    <div class="container-fluid" style={{ overflow: "hidden" }}><img src={picb24} style={{ width: "100%",height:"400px" }}></img></div>
                    
                </div>
                <CarouselSlider />
                <div className='container-fluid'>
                    <div className='row '>
                        <img alt='ipproval' src={picb25} />
                    </div>
                </div>
                <Promotion />
                <div className='row mx-auto cardbanner'>
                    <div className='col-md-4'>
                        <Link to={'/products'} onClick={(e) => {
                            var dep = "0001";
                            hddispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}>
                            <div class="card mb-1 bg-dark text-white">
                                <img src={picb23} class="card-img " style={{height:"500px"}} alt="..." />
                                <div class="card-img-overlay">
                                    <h5 class="card-title mt-5 ms-5">Laptop</h5>
                                    <p class="card-text ms-5">INDULGE IN THE TRUE FLAVORS</p>
                                    <button className='btn shopnow ms-5'> SHOP NOW</button>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className='col-md-4'>
                        <Link to={'/products'} onClick={(e) => {
                            var dep = "0003";
                            hddispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}>
                            <div class="card mb-1 bg-dark text-white">
                                <img src={picb22} class="card-img" style={{height:"500px"}} alt="..." />
                                <div class="card-img-overlay">
                                    <h5 class="card-title mt-5 ms-5">Mobile</h5>
                                    <p class="card-text ms-5">MOBILE IOS,Android DEVICE</p>
                                    <button className='shopnow btn  ms-5'> SHOP NOW</button>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-4'>
                        <Link to={'/products'} onClick={(e) => {
                            var dep = "0001";
                            hddispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}>
                            <div class="card mb-1 bg-dark text-white">
                                <img src={picb21} class="card-img" style={{height:"500px"}} alt="..." />
                                <div class="card-img-overlay">
                                    <h5 class="card-title mt-5 ms-5">ACCESSORIES</h5>
                                    <p class="card-text ms-5">VAPE ACCESSORIES MADE PERFECT</p>
                                    <button className='shopnow btn ms-5'> SHOP NOW</button>

                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <Pakistan  indi={"1"}/>


                <div className='container-fluid  ' id="comments" style={{ textAlign: "center" }}>
                    {/* <div className='newarrival container'  >
                        <div data-aos="fade-up" data-aos-duration="500" className="row  ">
                            <div className="col-md-4 col-sm-4 col-xs-4 ">
                                <div className="Line "></div>
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-3">
                                <h2 className="text-center arrival" style={{color:"black"}} >Testimonial</h2>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-4 ">
                                <div className="Line "></div>
                            </div>
                        </div>
                    </div> */}

                    <div className='row' id="Comments">
                        <div className='col-md-6 col-sm-6 justify-content-center commenpntpic' style={ {display:"flex", justifyContent:"center",alignItems:"center"}}>
                            {/* <h1>ipromovils</h1> */}
                            {/* <img src={"https://res.cloudinary.com/vape-house/image/upload/v1702740379/oo_su0lhm.png"} alt='assets' id="commentpic" /> */}
                        </div>
                        <div className='col-md-6 col-sm-12 col-xs-12 commenpntpic2  ' >
                            <div className='row'>
                                <div className='col-md-10 justify-content-center '>
                                    {/* <div id="slidee" className='justify-content-center' style={{border:"none"}}><Reviews />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <ReactVisibilitySensor onChange={counter}>
                    <div className='bg-fixed1' style={{backgroundColor:"#48afff"}}>
                        <div className='bg-colors'>
                            <div className='bg-contant1'>
                                <div className='bg-row'>
                                    <div className='row-cont1'>
                                        <div className='img-icon' >
                                            <GiWheat color='#48afff' size='48px' />
                                        </div>
                                        <div className="rtinn-counter">
                                            <span id='ingreedent' className="counter"></span>
                                        </div>
                                        <h3 className="rtinn-title">Original</h3>
                                    </div>
                                    <div className='row-cont1'>
                                        <div className='img-icon' >
                                            <VscAccount color='#48afff' size='48px' />
                                        </div>
                                        <div className="rtinn-counter">
                                            <span id='client' className="counter"></span>
                                        </div>
                                        <h3 className="rtinn-title">Top Quality</h3>
                                    </div>
                                    <div className='row-cont1'>
                                        <div className='img-icon' >
                                            <BsFillCalendarCheckFill color='#48afff' size='48px' />
                                        </div>
                                        <div className="rtinn-counter">
                                            <span id='years' className="counter"></span>
                                        </div>
                                        <h3 className="rtinn-title">Best Prices </h3>
                                    </div>
                                    <div className='row-cont1'>
                                        <div className='img-icon' >
                                            <GiBallHeart color='#48afff' size='48px' />
                                        </div>
                                        <div className="rtinn-counter">
                                            <span id='halal' className="counter"></span>
                                        </div>
                                        <h3 className="rtinn-title">Branded</h3>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </ReactVisibilitySensor>
                <Brands />
                <div className='delviry-sec'>
                    <div className='delviry-sec2'>
                        <div className='bg-colorr'>
                            <div className='delviry-bike'>
                                <img alt='bike' src={"https://res.cloudinary.com/vape-house/image/upload/v1702740467/bike_c865np.png"} />
                            </div>
                            <div className='bg-imgg'>
                                <center className='content-box'>
                                    <div className='left-content'>
                                        <h2>
                                            Get Free Delivery
                                        </h2>
                                        <p>As well known and we are very busy all days beforeso we can guarantee your seat.</p>
                                    </div>
                                    <div className='right-content'>
                                        <h3>
                                            <a href='tel:+34600000703'>  <IoIosCall style={{ marginRight: "10px", fontSize: "40px" }} />
                                                Call: 600000703
                                            </a></h3>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Body;
import React,{useEffect,useState} from 'react'
import RithIcon from './Screens/RithIcon'
import 'bootstrap/dist/css/bootstrap.min.css';
import { store } from './components/Redux/Store';
import { Provider } from 'react-redux';
import RootComponent from './RootComponent';
import { BrowserRouter} from "react-router-dom"
import Modal from 'react-modal';


const App = () => {
  const [openmodal,SetmodalOpen]=useState(false);

  useEffect(()=>{
    SetmodalOpen(false)
},[])
 
  return (
    
    < >
<Modal isOpen={openmodal} className="modal-content"   >
      
      <div className="">
                    <h4 className="modal-title">AGE VERIFICATION</h4>
                </div>
                <div className="modalBody"><br/>

                    <img className="model-logo" src="Logonpng.png" alt="Vape House"/>
                    
                    <h3 className="modalp">You must be 18 years old to visit this site.</h3>                        
                    <p className="modalp">
                        Some of the products available on this website contain nicotine.<br/>
                        Only proceed if you are over 18 years of age.
                    </p><br/>
                        <div className='row'>
                            <div className='col-7 justify-content-center text-center'>
                            <button id="confirm-btn" className="btn btn-lg text-light  justify-content-center mb-2 ms-1" onClick={()=>SetmodalOpen(false)} alt="YES, I AM OF LEGAL AGE"><span>YES, I AM OF LEGAL AGE</span></button>
                    
                            </div>
                            <div className='col-5 justify-content-center text-center'>
                            <a className='justify-content-center text-center' href='https://www.google.com/'><button className='btn btn-lg btn-outline-dark' >No, I am Not</button></a></div>
                        </div>
                </div>
                    
               
      

      </Modal>
    
    <Provider store={store}>
      <BrowserRouter>
      
        <RithIcon />
        <RootComponent />
        <stripHTML/>
      </BrowserRouter>
    </Provider>
    </>
  
  )
}

export default App;
import React, { useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Googlemap1, Googlemap2, Googlemap3, Googlemap4 } from "./Googlemap.js";
import './Checkout.css'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import contectus from "../assets/banner/contactus.jpg"



const ContactUs = () => {


    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [company, setCompany] = useState("")
    const [Subject, setSubject] = useState("")
    const [Message, setMessage] = useState("")
    // "Company": company,
    const contactusApi = () => {

        axios.post(`${window.sbapi}/api/Contactus`,
            {
                "Firstname": firstname,
                "Lastname":firstname,
                "Email": email,
                "Mobile": mobile,
               "Subject": Subject,
                "Message": Message
            })
            .then((respon) => {
                if (respon.data.Message === "Success") {
                    toast.success('Messzge has been sent Successfuly', {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setFirstname("")
                    setLastname("")
                    setEmail("")
                    setMobile("")
                    setCompany("")
                    setSubject("")
                    setMessage("")

                }
                else {
                    // console.log('Fail')
                }
            }).catch((er) => {
                // console.log('er', er)
            })
    }

    return (

        <>
            <Helmet>
                <title>contactus</title>
                <meta name="discription" content="VapeHouse Contactus" />
            </Helmet>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Header />
            
            <div className="ros" style={{width:"100%", }}>
                <img src={contectus} alt="contactus" style={{width:"100%"}}  />
            </div>
            <div style={{ background: "#f4eddd", padding: "30px" }}>
                <div className="alldataa row">

                    <div style={{height:"70%"}} className="formss col-sm-12 col-md-7 col-lg-7">
                        <div className="forms-aa">
                            <h1>  We Love To Hear From You</h1>
                        </div>
                        <form className="forms-bb">
                            <input type='text' value={firstname} onChange={e => setFirstname(e.target.value)} placeholder="Name" />
                            {/* <input type='text' value={lastname} onChange={e => setLastname(e.target.value)} placeholder="Last Name" /> */}
                            <input type='email' value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                            <input maxLength={11} value={mobile} onChange={e => setMobile(e.target.value)} type='tel' placeholder="Mobile" />
                          {/* /  <input type='text' value={company} onChange={e => setCompany(e.target.value)} placeholder="Your Company:" /> */}
                            <input type='text' value={Subject} onChange={e => setSubject(e.target.value)} placeholder="Subject:" />
                            <textarea value={Message} onChange={e => setMessage(e.target.value)} placeholder="Message" />

                        </form>
                        <div  className="btn">
                            <button style={{fontWeight:"bold",fontSize:15}} onClick={contactusApi}> Submit </button>
                        </div>
                    </div>

                    <div className="infoo col-sm-12 col-md-4 col-lg-4">
                        <h1>The Ipro Movils Outlets</h1>
                        <h2>Barcelona:</h2>
                        <p style={{marginTop:2}}>Address: Carrer ROMON, 2 (08001) </p>
                        <p style={{ fontWeight: "2000" }}><strong>E-Mail:</strong><> info@ipromovils.com</></p>
                        <p style={{marginTop:2}}><strong>Contact:</strong> 3460000703</p>


{/* 
                        <h2>Multan:</h2>
                        <p style={{marginTop:2}}>Shop No.15, Saleem Mall, Goal Bagh, Gulgashat Colony, Multan</p>
                        <p style={{marginTop:2}}><strong>Contact:</strong> 0312 745 25 50</p>

                        <h2>Bahawalpur:</h2>
                        <p style={{marginTop:2}}>Shop No.17, Cantonment Market, Saraiki Chowk, Model Town, Bahawalpur</p>
                        <p style={{marginTop:2}}><strong>Contact:</strong> 0300 698 34 33</p>

                        <h2>Sialkot:</h2>
                        <p style={{marginTop:2}}>Shop No. LG-6, Cantt Mall, Aziz Bhatti Shaheed Road, Saddar  Cantt, Sialkot</p>
                        <p style={{marginTop:2}}><strong>Contact:</strong>  0333 6800 812</p> */}



                    </div>

                </div>
                {/* <div className="google1-map">
                    <Googlemap2 />
                </div>
                <div className="google-map">
                    <div className="google-map1" >
                        <Googlemap1 />
                    </div>
                    <div className="google-map2" >
                        <Googlemap4 />
                    </div>
                    <div className="google-map2" >
                        <Googlemap3 />
                    </div>
                </div> */}
            </div>

            <Footer />
        </>
    )
}

export default ContactUs;
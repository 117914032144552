import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../components/Body/Body.css';
import { MdShoppingCart } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddToCart,setItemData } from '../components/Redux/Action';
import { HiMinusSm, HiPlusSm } from 'react-icons/hi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CarouselSlider = ({ proData, setProData }) => {
    const dispach=useDispatch();
    const promotionalNewArival=useSelector(state=>state?.Reducerprodata)
    const signupInfo = useSelector(state => state.ReducerSignUpInfo)
    const [prodata, setProdata] = useState();


    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1024,
                settings: {

                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 500,
                settings: {

                    slidesToShow: 2,
                },
            },
        ],
    }

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(true);


  
  




    useEffect(() => {
        // axios.get(`https://easyapivarivh.thevapehouse.pk/api/Product/PromoandNewArrivalProducts?BusinessId=0000000001`)
        //     .then((repss) => {
        //         if (repss.data.Message === 'Success') {
        //             let apidata = repss.data.Data.NewArrivalProducts
        //             setProdata(apidata)
        //         }
        //         else {
        //             alert("error")
        //         }
        //     })

            setProdata(promotionalNewArival?.newArrival)
    }, [promotionalNewArival])






    return (


        <>
        <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            
<div className='newarrival container mb-5 mt-5'  >
           <div data-aos="fade-up" data-aos-duration="500" className="row  ">
                {/* <div className="col-md-1  ">
                    <div className="Line "></div>
                </div> */}
                <div  className="col-md-10 ">
                    <h2  className="text-center arrival" style={{color:"#48afff"}} >Best Asseccories Collection</h2>
                   
                </div>
                {/* <div className="col-md-1 ">
                <div className="Line px-5"></div>
                </div> */}
                <Link className="viewall"  style={{borderColor:"#48afff"}} to="/products"> 
                <div style={{fontSize:"16px" }} > view all</div>
                </Link>
            </div>
            <p style={{fontSize:16}}>
                    In World, We provides the most extensive collection of Asseccories online. We stock top Accessories, Mobile, Leptop brands such as Apple, Mokia , Dell , HP, SumSung, and many more. Additionally, we proudly feature the most comprehensive Moblie and Leptop series collection, which includes ProMax, Infinix, AirPod and MacBook .
                    </p>
           </div>

           



        

            <Slider  {...settings} className="mx-2 px-8 mb-5">

            {
                    

                        prodata?.map((item, index) => {
                            return (
                                <div className='product-main-body px-1 '>
                                <div className='product_container1' id='slideId' >
                                   {
                                       item.PromoPercentage ?
                                       <span className='discount-offers'>  {item.PromoPercentage}% OFF</span> :
                                       ""
                                   }
                                    <Link to={'/product-detail'} state={{ item }} onClick={() => { window.scrollTo(0, 0); dispach(setItemData(item)) }}>
                                        <img alt={`${window.imagesapi}/images/ProductImages/${item.Barcode}.webp`} src={`${window.imagesapi}/images/ProductImages/${item.Barcode}.webp`}  />
                                    </Link>

                                    <div className='P-product' style={{marginTop:"10px"}}>{item.Description}</div>
{signupInfo?.Data?.Approved=="A"?<>
<span className='cr-price'style={{marginTop:"10px"}}> € . {item.UnitRetail - (item.UnitRetail * item.PromoPercentage / 100)}
{/* <div>{item}}</div> */}
                                     <del className="oldPrice"> {item.PromoPercentage ?` € :${item.UnitRetail}`  : null}</del></span>
                                    <div className='product-cart' style={{border: "1px solid #48afff", borderRadius:"6px"}}>
                                        <div className='product-cart1'>
                                            {/* <div className="qty-main">
                                                    <a className="plus-a" onClick={() => increment(item, index)} >
                                                        <HiPlusSm />
                                                    </a>
                                                    <div className="qty-first">
                                                        <p >{item.qty}</p>
                                                    </div>
                                                    <a className="minas-a" onClick={() => decrement(item, index)} >
                                                        <HiMinusSm />
                                                    </a>
                                                </div> */}
                                        </div>
                                        <div className='product-cart2'  >
                                            <button onClick={() => {
                                                dispatch(AddToCart(item))
                                                toast.success('  Product Added Successfully', {
                                                    position: "top-center",
                                                    autoClose: 1000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                })
                                            }}>
                                                <MdShoppingCart />
                                            </button>
                                        </div>
                                    </div>
</>:    <Link to={"signup"}> <div>
    <h3 style={{color:"#48afff"}}>Please login to view its price</h3>
        </div>
        </Link>

}
                                   




                                </div>
                                </div>
                            )
                        }) 

                }
            </Slider> 
        </>
    );
};


export default CarouselSlider;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Loginform.css'
import { GiCrossedBones } from 'react-icons/gi';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSignUp } from '../Redux/Action';

const Loginform = (props) => {
    
    const signupInfo = useSelector(state => state.ReducerSignUpInfo)
    const [loginname1, setLoginame1] = useState("")
    const [loginpassword1, setLoginpassword1] = useState("")
const dispatch=useDispatch()
    const loginApiA = () => {

        axios.get(`${window.sbapi}/api/partyregister/PartyLogin?mobileoremail=${loginname1}&password=${loginpassword1}&partyType=c`).then((repss) => {
            if (repss.data.Message === 'Success') {
                dispatch(setSignUp(repss.data))
                toast.success(' You are Successfully Login', {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoginame1("")
                setLoginpassword1("")
            }
            else{
                alert("Pleas Check Your Username & Password")
            }
        })
    }

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                pauseOnFocusLoss
                draggable 
                pauseOnHover />
           
                <span id='loginback' >
                    {
                         signupInfo?.Data?.Approved=="A"?               <div className='login-form' style={{height:"100px"}}>
                         <div style={{color:"white",fontSize:"16px",margin:"5px"}}>User Name : {signupInfo?.Data?.PartyName} </div>
                         <div  onClick={()=>{
                            dispatch(setSignUp({}))
 console.log("data of user=======> ",  signupInfo?.Data)
                         }} style={{justifyContent:"center",alignItems:"center"}} className='login-btn'>
                         <div style={{fontSize:"18px"}}> Logout </div>
                         </div>
                       
                     </div>:         signupInfo?.Data?.Approved=="I"?      <div className='login-form' style={{height:"100px"}}>
                        <div style={{color:"white",fontSize:"16px",margin:"5px"}}>Waiting for approval </div>
                        <div  onClick={()=>{
                            dispatch(setSignUp({}))
 console.log("data of user=======> ",  signupInfo?.Data)
                         }} style={{justifyContent:"center",alignItems:"center"}} className='login-btn'>
                         <div style={{fontSize:"18px"}}> Logout </div>
                         </div>
                        {/* <a onClick={()=>{

                        }} style={{height:"40px" , width:"150px"}}> Logout </a> */}
                    </div>:     <div className='login-form' style={{height:"350px"}} >
                    <button className='cancel-btn' onClick={() => props.setToggleLogin(false)} > <GiCrossedBones id='logincross' /> </button>
                    <p className='Welcome'>Welcome! Have you shopped before?</p>
                    <div 
                    >
                    <input type='email'  value={loginname1} onChange={e => setLoginame1(e.target.value)} placeholder="User Name / E-mail " style={{fontSize:"14px" ,height:"30px",width:"250px"}} />
                    </div>
                    <div>
                    <input type='password' value={loginpassword1} onChange={e => setLoginpassword1(e.target.value)} placeholder='Password' style={{fontSize:"14px",height:"30px",width:"250px"}}/>
                    </div>

                  
                    <button className='login-btn' onClick={loginApiA} style={{fontSize:"14px"}}>LOGIN</button>
                    <p>
                        <Link to={'/recovery'} className='forget' style={{marginBottom:"0px"}} > Forget Password? </Link>
                    </p>
                    <p className='register-here' style={{marginTop:"0px",color:"white",fontSize:"14px"}}>Create Account?
                        <Link to={'/signup'}style={{color:"yellow"}}  className='register-link'  > Register Here!  </Link>
                    </p>
                </div>
                    }
     
           
            </span>
        </>
    );
};

export default Loginform;
import React from 'react'
import { FaUnderline } from 'react-icons/fa'
import './Common.css'

const Pakistan = (props) => {
  return (
    <>
    {
        props.indi=="1"?<div className='container mt-5' style={{overflow:"hidden"}}>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padd-right">
            <div style={{marginBottom:"70px"}}>
                    <h1 data-aos="fade-left" data-aos-duration="1000"  className="text-center arrival" style={{color:"#48afff"}}>In The World</h1><br/>
                
                    <h3 data-aos="fade-right" data-aos-duration="1000" style={{fontSize:"25px",color:"black", marginBottom:"20px", textDecoration:"underline", textDecorationColor:"green", textUnderlinePosition:"under"}} className="text-center arrival">Largest Asseccories Store Network</h3>
                    
                    

                <p data-aos="zoom-out" data-aos-duration="1000" className="text-center text px-3 " style={{color:"black",backgroundColor:"white",justifyContent:"left", fontSize:"16px",textAlign:"left", lineHeight:"30px"}}>
                <span >All electronic components such as: screens, flex, touch screens, lenses, repair parts, etc., are exclusive products for specialized workshops, not for the end user. If you purchase any of these products we assume that you are a specialized workshop and not an end user, which is why you are not subject to the warranty law. These types of products lack warranty if they show signs of use.

The maximum period to request the return of defective products or products that customers do not want is 90 days *, counting from the date of shipment.

The manipulation, breakage or absence of the warranty seal implies the automatic cancellation of the same. The manufacturer requires that at the time of receiving the part, BEFORE BEING</span>
                </p>

            </div>
            {/* <div id="" style={{marginBottom:"70px"}}>
                    
                    <h3 data-aos="fade-right" data-aos-duration="1000" style={{fontSize:"25px",fontWeight:"1000", marginBottom:"20px", textDecoration:"underline", textDecorationColor:"green", textUnderlinePosition:"under"}} className="text-center" >LARGEST CHAIN OF VAPE STORES</h3>

                <p data-aos="zoom-out" data-aos-duration="1000" className="text-center text px-3" style={{color:"black",backgroundColor:"white",justifyContent:"left", fontSize:"16px",textAlign:"left", lineHeight:"30px"}}>
                <span >Elite Cigs & Vaporizers is the leading vape store in Pakistan having a chain of outlets in Karachi, Lahore, Multan, Hyderabad and Sialkot. We have the largest stock of vape products from the leading international brands. Our store offer all types of POD MOD and vape juices at discounted prices. We serve only the top quality Ecigs, Vape juices like Vape MODS, portable devices, Kits and all type of accessories at an affordable price. Visit our store today at your city.
<br></br>
<p style={{margin:"20px"}}> Lahore | Multan | Bahawalpur | Sialkot
 | </p></span>
                </p>

            </div> */}
            <div id="pakistan">
                    
                
                    <h3 data-aos="fade-right" data-aos-duration="1000" style={{fontSize:"25px", marginBottom:"20px", textDecoration:"underline",color:"black", textDecorationColor:"green", textUnderlinePosition:"under"}} className="text-center arrival" >The Premier Asseccories Shop Near </h3>

                <p data-aos="zoom-out" data-aos-duration="1000" className="text-center text px-3" style={{color:"black",backgroundColor:"white",justifyContent:"left", fontSize:"16px",textAlign:"left", lineHeight:"30px"}}>
                <span >You have come to the right place if you are searching for a Asseccories shop near me because Asseccories Station is World’s leading Asseccories Store for all your Asseccories needs. Our staff is always ready to assist you in finding the perfect set of products, be it devices   , to suit your preferences and needs. Visit our store outlets or order online for delivery all over Pakistan.</span>
                </p>

            </div>
        </div>
        </div>
        :null
    }
    {
        props?.indi=="2"?<div className='container mt-3' style={{overflow:"hidden"}}> <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padd-right">
        <div style={{marginBottom:"20px"}}>
                <h1 data-aos="fade-left" data-aos-duration="1000" style={{color:"#48afff"}} className="text-center arrival ">Online Accessories store in the World</h1><br/>
            
                {/* <h3 data-aos="fade-right" data-aos-duration="1000" style={{fontSize:"25px",fontWeight:"1000", marginBottom:"20px", textDecoration:"underline", textDecorationColor:"green", textUnderlinePosition:"under"}} className="text-center">LARGEST VAPE STORE NETWORK</h3> */}
                
                

            <p data-aos="zoom-out" data-aos-duration="1000" className="text-center text px-3 " style={{color:"black",backgroundColor:"white",justifyContent:"left", fontSize:"16px",textAlign:"left", lineHeight:"30px"}}>

          We boasts one of the most extensive collections of Mobile and Leptop available for online sale in World. Our product range encompasses Leptop, Mobile, Its Asseccories and Protector.
            </p>

        </div>
        </div>
        {/* <div id="" style={{marginBottom:"70px"}}>
                
                <h3 data-aos="fade-right" data-aos-duration="1000" style={{fontSize:"25px",fontWeight:"1000", marginBottom:"20px", textDecoration:"underline", textDecorationColor:"green", textUnderlinePosition:"under"}} className="text-center" >LARGEST CHAIN OF VAPE STORES</h3>

            <p data-aos="zoom-out" data-aos-duration="1000" className="text-center text px-3" style={{color:"black",backgroundColor:"white",justifyContent:"left", fontSize:"16px",textAlign:"left", lineHeight:"30px"}}>
            <span >Elite Cigs & Vaporizers is the leading vape store in Pakistan having a chain of outlets in Karachi, Lahore, Multan, Hyderabad and Sialkot. We have the largest stock of vape products from the leading international brands. Our store offer all types of POD MOD and vape juices at discounted prices. We serve only the top quality Ecigs, Vape juices like Vape MODS, portable devices, Kits and all type of accessories at an affordable price. Visit our store today at your city.
<br></br>
<p style={{margin:"20px"}}> Lahore | Multan | Bahawalpur | Sialkot
| </p></span>
            </p>

        </div> */}
       
    </div>:null
 
        

    }
        
    </>
  )
}

export default Pakistan

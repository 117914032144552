import React, { useEffect } from 'react'
import {  Routes, Route } from "react-router-dom";
import Home from './Screens/Home';
import AboutUs from './Screens/AboutUs';
import ContactUs from './Screens/ContactUs';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import DeliveryInfo from './Screens/DeliveryInfo';
import TermsConditions from './Screens/TermsCoditions';
import Signup from './Screens/Signup';
import ProductDetail from './Screens/ProductDetail';
import ViewCart from './Screens/ViewCart';
import ProductsPage from './Screens/ProductsPage';
import Checkout from './Screens/Checkout';
import Loginform from './components/Header/Loginform';
import Recovery from './Screens/Recovery';
import TrackMyOrder from './Screens/TrackMyOrder';
import Review from './Screens/Review';
import Viewcartitem from './Screens/Viewcartitem';
import Orderhistory from './Screens/Orderhistory';
import { useDispatch } from 'react-redux';
import { DepartmentApi, fetchApi, PoductsApi, BrandApi } from './components/Redux/Action';
import { store } from './components/Redux/Store';
import { Provider } from 'react-redux';
import RithIcon from './Screens/RithIcon';
import BrandProduct from './Screens/BrandProduct';
import Gallary from './Screens/Gallary';


const RootComponent = () => {
    useEffect(()=>{
      },[]);
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(DepartmentApi())
        dispatch(fetchApi())
        dispatch(PoductsApi())
        dispatch(BrandApi())
} ,[])
    return (
        <Provider store={store}>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path="about-us" element={<AboutUs />} />
                <Route exact path="contact-us" element={<ContactUs />} />
                <Route exact path="privacy-policy" element={<PrivacyPolicy />} />
                <Route exact path="term-condition" element={<TermsConditions />} />
                <Route exact path="delivery-info" element={<DeliveryInfo />} />
                <Route exact path="signup" element={<Signup />} />
                <Route exact path="products/signup" element={<Signup />} />
                {/* product-detail/signup */}
                <Route exact path="product-detail/signup" element={<Signup />} />
                <Route exact path="viewcart" element={<ViewCart />} />
                <Route exact path="viewcartitems" element={<Viewcartitem />} />
                <Route exact path="checkout" element={<Checkout />} />
                <Route exact path="loginform" element={<Loginform />} />
                <Route exact path="recovery" element={<Recovery />} />
                <Route exact path="track-my-order" element={<TrackMyOrder />} />
                <Route exact path="product-detail" element={<ProductDetail />} />
                <Route exact path="products" element={<ProductsPage />} />
                <Route exact path="brand-products" element={<BrandProduct /> } />
                <Route exact path="product-review" element={<Review />} />
                <Route exact path="order-history" element={<Orderhistory />} />
                <Route exact path="righticon" element={<RithIcon />} />
                <Route exact path="gallary" element={<Gallary />} />

            </Routes>
         </Provider>
         
    )
}



export default RootComponent

